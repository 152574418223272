"use client";

// app/[locale]/login/page.tsx
import { signIn, useSession } from "next-auth/react";
import { useLocale } from "next-intl";
import { useRouter } from "next/navigation";
import { useEffect } from "react";
export default function Login() {
  const locale = useLocale();
  const router = useRouter();
  const isFactagora = process.env.NEXT_PUBLIC_BRANCH === "factagora";
  const {
    data: session,
    status
  } = useSession();
  useEffect(() => {
    console.log("useEffect triggered");
    console.log("status:", status);
    console.log("session:", session);
    if (status === "loading") return;
    if (!session) {
      if (isFactagora) {
        console.log("No session, redirecting to custom sign-in");
        router.push(`/${locale}/custom-signin`);
      } else {
        signIn("azure-ad", {
          callbackUrl: `/${locale}`
        });
      }
    } else {
      console.log("Session exists, redirecting to locale");
      router.push(`/${locale}`);
    }
  }, [locale, router, isFactagora, session, status]);
  return null;
}